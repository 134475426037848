var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('portal-target',{attrs:{"name":"BreadCrumbs"}},[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('router-link',{staticClass:"is-active",attrs:{"tag":"li","to":{
            name: 'NotesDetails',
            params: { id: this.$route.params.id },
          }}},[_c('a',[_vm._v("Notatki")])])],1)])]),_c('div',{staticClass:"ym-toptitle-box"},[_c('fixed-header',{attrs:{"threshold":119,"fixedClass":"ym-toptitle-fixed"}},[_c('div',{staticClass:"level ym-toptitle",attrs:{"id":"toptitle"}},[_c('div',{staticClass:"level-left"},[_c('router-link',{staticClass:"button xbtn is-medium is-transparent",attrs:{"tag":"a","to":"/notes"}},[_c('icon',{attrs:{"name":"x"}})],1),_c('h2',{staticClass:"title level-left"},[_vm._v("Notatka")])],1),_c('div',{staticClass:"buttons level-right"},[_c('button',{staticClass:"button is-medium is-light",on:{"click":_vm.removeNote}},[_c('icon',{attrs:{"name":"trash"}}),_c('span',[_vm._v("Usuń")])],1),_c('router-link',{staticClass:"button is-medium is-light",attrs:{"tag":"a","to":{
              name: 'NotesEdit',
              params: { id: parseInt(this.$route.params.id) },
            }}},[_c('icon',{attrs:{"name":"edit"}}),_c('span',[_vm._v("Edytuj")])],1)],1)])])],1),(_vm.note)?_c('div',{staticClass:"ym-whitebg pa40"},[_c('div',{staticClass:"columns is-variable is-6"},[(_vm.note.note)?_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"label-small"},[_vm._v("Treść notatki")]),_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.note.note))])]):_vm._e()]),_c('div',{staticClass:"columns is-variable is-6"},[(_vm.note.recipients)?_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"label-small"},[_vm._v("Odbiorcy")]),_vm._l((_vm.note.recipients),function(recipent){return _c('p',{key:recipent.id,staticClass:"label"},[_vm._v(" "+_vm._s(recipent.name)+" "),(recipent.readed_at)?_c('span',[_vm._v("(Przeczytał "),_c('small',[_vm._v(_vm._s(recipent.readed_at))]),_vm._v(")")]):_vm._e()])})],2):_vm._e()])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }