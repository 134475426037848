<template>
  <section>
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link
            tag="li"
            :to="{
              name: 'NotesDetails',
              params: { id: this.$route.params.id },
            }"
            class="is-active"
            ><a>Notatki</a></router-link
          >
        </ul>
      </nav>
    </portal-target>
    <div class="ym-toptitle-box">
      <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
        <div id="toptitle" class="level ym-toptitle">
          <div class="level-left">
            <router-link
              tag="a"
              to="/notes"
              class="button xbtn is-medium is-transparent"
              ><icon name="x"></icon
            ></router-link>
            <h2 class="title level-left">Notatka</h2>
          </div>
          <div class="buttons level-right">
            <button @click="removeNote" class="button is-medium is-light">
              <icon name="trash"></icon><span>Usuń</span>
            </button>
            <router-link
              tag="a"
              :to="{
                name: 'NotesEdit',
                params: { id: parseInt(this.$route.params.id) },
              }"
              class="button is-medium is-light"
              ><icon name="edit"></icon><span>Edytuj</span></router-link
            >
          </div>
        </div>
      </fixed-header>
    </div>
    <div class="ym-whitebg pa40" v-if="note">
      <div class="columns is-variable is-6">
        <div class="column is-6" v-if="note.note">
          <p class="label-small">Treść notatki</p>
          <p class="label">{{ note.note }}</p>
        </div>
      </div>
      <div class="columns is-variable is-6">
        <div class="column is-6" v-if="note.recipients">
          <p class="label-small">Odbiorcy</p>
          <p
            class="label"
            v-for="recipent in note.recipients"
            :key="recipent.id"
          >
            {{ recipent.name }}
            <span v-if="recipent.readed_at"
              >(Przeczytał <small>{{ recipent.readed_at }}</small
              >)</span
            >
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";
import YMmodal from "@/components/Modal.vue";

export default {
  name: "NotesDetails",
  components: {
    FixedHeader,
  },
  data: function () {
    return {
      note: {},
    };
  },
  mounted() {
    this.getNote();
  },
  computed: {
    ...mapGetters({
      selectADevice: "orders/selectADevice",
      order: "orders/order",
    }),
  },
  methods: {
    ...mapActions({
      getUserNotes: "auth/userNotes",

      getUserNoteCall: "records/getUserNote",
      removeUserNoteCall: "records/removeUserNote",
    }),
    getNote() {
      this.getUserNoteCall({ id: this.$route.params.id })
        .then((resp) => {
          this.note = resp;
          this.getUserNotes();
        })
        .catch((error) => {
          this.note = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    removeNote() {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz usunąć notatke?",
          content: `<span class='is-danger'>Usunięcia notatki nie można cofnąć.</span>`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "trash",
          submit: `<span class="mal50i">Usuń</span>`,
          ymConfirm: true,
          ymOnConfirm: () => {
            this.removeUserNoteCall({ id: this.$route.params.id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.$router.push({ name: "Notes" });
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    selectDevice() {
      this.$store.dispatch("orders/setDevice", this.machinery).then(() => {
        if (this.order && this.order.id) {
          this.$router.push({
            name: "ServiceEdit",
            params: { id: parseInt(this.order.id) },
          });
        } else {
          this.$router.push({ name: "ServiceAdd" });
        }
      });
    },
    statusName: function () {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === this.machinery.order.status_id
      );
      if (status) {
        return status[0].name;
      }
    },
    statusColor: function () {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === this.machinery.order.status_id
      );
      if (status.length > 0) {
        return `color: ${status[0].colour}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
