<template>
  <section v-if="dictionary && Object.keys(dictionary).length > 0">
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/notes"><a>Notatki</a></router-link>
          <router-link tag="li" to="/notes/add" class="is-active"
            ><a>Dodaj</a></router-link
          >
        </ul>
      </nav>
    </portal-target>

    <NotesForm />
  </section>
</template>

<script>
import NotesForm from "@/views/Notes/NotesForm";
import { mapGetters } from "vuex";

export default {
  name: "NotesAdd",
  components: {
    NotesForm,
  },
  computed: {
    ...mapGetters({
      dictionary: "dictionary",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
