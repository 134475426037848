<template>
  <div v-if="dictionary && Object.keys(dictionary).length > 0">
    <ValidationObserver ref="formObserver" v-slot="{ errors }">
      <form class="login" @submit.prevent="onSubmitScroll">
        <div class="ym-toptitle-box">
          <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
            <div id="toptitle" class="level ym-toptitle">
              <div class="level-left">
                <router-link
                  tag="a"
                  to="/notes"
                  class="button xbtn is-medium is-transparent"
                >
                  <icon name="x"></icon>
                </router-link>
                <h2 class="title" v-if="isNew">Dodaj</h2>
                <h2 class="title" v-else>Edytuj</h2>
              </div>
              <div class="buttons level-right">
                <button class="button is-medium is-primary">
                  <icon name="check"></icon><span>Zapisz</span>
                </button>
              </div>
            </div>
          </fixed-header>
        </div>
        <div
          :class="
            errors['note'] && errors['note'].length > 0
              ? 'columns is-variable is-6 is-multiline v-field v-error'
              : 'columns is-variable is-6 is-multiline'
          "
        >
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Treść notatki</label>
              <span
                v-if="errors['note'] && errors['note'].length > 0"
                class="help is-danger"
                >{{ errors["note"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="note">
                  <textarea
                    name="note"
                    v-model="note.note"
                    class="input is-medium"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['user_ids'] && errors['user_ids'].length > 0
              ? 'columns is-variable is-6 is-multiline v-field v-error'
              : 'columns is-variable is-6 is-multiline'
          "
        >
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Odbiorcy</label>
              <span
                v-if="errors['user_ids'] && errors['user_ids'].length > 0"
                class="help is-danger"
                >{{ errors["user_ids"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <b-checkbox
                  v-model="sendToAllUsers"
                  size="is-medium"
                  :true-value="1"
                  :false-value="0"
                >
                  Do wszystkich
                </b-checkbox>
                <p>lub wybierz indywidualnie</p>
                <ValidationProvider name="user_ids">
                  <multiselect
                    name="user_ids"
                    v-model="note.user_ids"
                    :options="users"
                    :multiple="true"
                    :group-select="false"
                    placeholder="Wyszukaj"
                    selectLabel="Wybierz"
                    track-by="fullname"
                    :label="`fullname`"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <div class="option__title">
                        {{ props.option.fullname }}
                      </div>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="option__title">
                        {{ props.option.fullname }}
                      </div>
                    </template>
                  </multiselect>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "NotesForm",
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FixedHeader,
    ValidationObserver,
    ValidationProvider,
    Multiselect,
  },
  data: function () {
    return {
      note: {
        note: "",
        user_ids: [],
      },
      users: [],
      sendToAllUsers: 1,
    };
  },
  watch: {
    "note.user_ids": function () {
      if (this.note.user_ids.length > 0) {
        this.sendToAllUsers = 0;
      } else {
        this.sendToAllUsers = 1;
      }
    },
    sendToAllUsers: function () {
      if (this.sendToAllUsers === 1) {
        this.note.user_ids = [];
      }
    },
  },
  computed: {
    ...mapGetters({
      selectADevice: "orders/selectADevice",
      dictionary: "dictionary",
      user: "auth/user",
    }),
    isNew() {
      return this.id === 0 ? true : false;
    },
  },
  methods: {
    ...mapActions({
      getUserNote: "records/getUserNote",
      postUserNote: "records/postUserNote",
      getUsersCall: "records/getUsers",
      disabledSidebar: "setDisabledSidebar",
    }),
    getUsers() {
      const getTechnical = this.getUsersCall({
        page: 1,
        perPage: 100000,
        sortField: "lastname",
        sortOrder: "asc",
        searchQuery: "",
        searchDeleted: 0,
        function_role: "technical",
      });
      const getOperator = this.getUsersCall({
        page: 1,
        perPage: 100000,
        sortField: "lastname",
        sortOrder: "asc",
        searchQuery: "",
        searchDeleted: 0,
        function_role: "operator",
      });
      const allPromise = Promise.all([getTechnical, getOperator]);
      allPromise
        .then((values) => {
          this.users = [...values[0].data, ...values[1].data];
          this.users = this.users
            .map((user) => {
              user.fullname = `${user.firstname} ${user.lastname}`;
              return user;
            })
            .sort((a, b) => a.lastname.localeCompare(b.lastname));
          const userId = this.user.id;
          const userIndex = this.users.findIndex((user) => user.id === userId);
          if (userIndex >= 0) {
            this.users.splice(userIndex, 1);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      //   this.getUsersCall({
      //     page: 1,
      //     perPage: 100000,
      //     sortField: "lastname",
      //     sortOrder: "asc",
      //     searchQuery: "",
      //     searchDeleted: 0,
      //     function_role: "technical",
      //   })
      //     .then((resp) => {
      //       this.users = resp.data;
      //       this.users = this.users.map((user) => {
      //         user.fullname = user.firstname + " " + user.lastname;
      //         return user;
      //       });
      //     })
      //     .catch((error) => {
      //       this.technicians = [];
      //       this.$buefy.toast.open({
      //         duration: 5000,
      //         message: error.data.error_description,
      //         position: "is-bottom",
      //         type: "is-danger",
      //       });
      //     });
    },
    onSubmitScroll() {
      this.$refs.formObserver.validate().then((success) => {
        if (!success) {
          for (const key of Object.keys(
            this.$refs.formObserver.fields
          ).sort()) {
            if (this.$refs.formObserver.fields[key].invalid) {
              const y =
                this.$refs.formObserver.refs[key].$el.getBoundingClientRect()
                  .top;
              this.$refs.formObserver.refs[key].$el.scrollIntoView({
                top: y,
                behavior: "smooth",
              });
              return;
            }
          }
        }

        this.onSubmit();
      });
    },
    onSubmit() {
      this.note.user_ids = this.note.user_ids.map((user) => user.id);

      this.postUserNote({
        note: this.note,
        id: this.$route.params.id,
      }).then((resp) => {
        if (resp.data) {
          this.$router.push({
            name: "NotesDetails",
            params: { id: resp.data.id },
          });
        } else {
          this.$router.push({
            name: "Notes",
          });
        }
      });
    },
  },
  async mounted() {
    this.disabledSidebar(true);
    await this.getUsers();
    if (!this.isNew) {
      this.getUserNote({ id: this.$route.params.id })
        .then((resp) => {
          this.note = resp;
          this.note.user_ids = [];
          if (this.note.recipients) {
            this.note.recipients.forEach(({ id }) => {
              const user = this.users.find((user) => user.id === id);
              if (user) {
                this.note.user_ids.push(user);
              }
            });
          }
        })
        .catch((error) => {
          this.note = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    }
  },
  destroyed() {
    if (!this.selectACustomer) {
      this.disabledSidebar(false);
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.buttons-wrap {
  margin-top: 8px;
  flex-wrap: wrap;
}
.control + .control {
  margin-top: 8px;
}
.select {
  width: 100%;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  padding-left: 8px;
}
.is-info {
  margin-top: 1rem;
  background-color: #3e8ed0;
  color: #fff;
  padding: 1rem;
}
.column-wrap {
  display: flex;
  gap: 16px;

  & > .field {
    width: 100%;
  }

  & .is-small {
    margin-top: 8px;
  }
}
</style>

<style scoped>
.buttons-wrap {
  margin-top: 8px;
  flex-wrap: wrap;
}
</style>
